import React from "react";

import "../News/Article.scss";
import Layout from "../../components/Layout/Layout";

const Article17092021 = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">
              The very latest in managed printing for Kaufland Moldova{" "}
            </h1>
            <p>
              Comprehensive management and improvement of print and document
              capture processes are important components of powerful managed
              printing concepts. For this reason, S&T Moldova's Digital Document
              Services bridge the gap between the classic managed printing
              business and digitalization projects.
            </p>
            <p>
              S&T Moldova`s current project for Kaufland Moldova is based on
              legendary Hewlett Packard Incorporated quality and reliability and
              S&T Moldova`s high-performance managed printing solutions.
            </p>
            <p>
              This project exists more than 2 years, since Kaufland entered on
              Moldovan Market. Kaufland is one of the largest retailers in
              Europe with 1,270 stores and more. At that moment, 3 stores have
              already been opened in Moldova, and over the next ten years the
              company plan to open 20 new stores in our country as well.{" "}
            </p>
            <p>
              The concept of this project was chosen as “Printing as a Service”
              and represent a complex of printing solutions that help to
              optimize a company’s document output so important in a retail
              sector.{" "}
            </p>
            <p>
              The latest HP Inc tools for efficient printer fleet management
              help reduce IT workload and costs, enhance employee productivity,
              and enable users to print securely. In addition, the main
              components provided are needs assessment, selective or general
              replacement of hardware, and the service, parts and supplies
              needed to operate the new and/or existing.
            </p>
            <p>
              S&T Moldova local authorized technical service center provide 24x7
              support and tracks how the printer, fax, copier, and MFP fleet is
              being used, the problems, and the user’s satisfaction. “Printing
              as a Service” is innovative concept for Moldova but drive
              productivity and profitability, streamline, automate and mobilize
              business processes to enhance productivity.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Article17092021;
